export default class Accordion {
    constructor() {
        const accordionToggle = document.querySelectorAll(".faq--single-title-wrapper");
        const wrapper = document.querySelectorAll('.faq--single-wrapper');

        wrapper.forEach(item => {
            item.addEventListener('click', () => {
                const isItemOpen = item.classList.contains("open");

                wrapper.forEach(item => item.classList.remove('open'));

                if (!isItemOpen) {
                    item.classList.add("open");
                }
            })
        });
    }
}