import '@styles/tailwind.css';
import '@styles/main.scss';
import ProductDots from './components/single-product-colour-dots';
import HowToGallery from './components/how-to-gallery';
import CategoryFilters from './components/news-category-filter';
import Menu from './components/menu';
import Accordion from './components/Accordion';
import ProductFAQ from './components/product-faq';
// import VideoPlayer from './components/more-info-video-player';

class App {
    constructor() {
        new ProductDots();
        // new VideoPlayer();
        new HowToGallery();
        new Menu();
        new Accordion();
        new ProductFAQ();
        new CategoryFilters();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
