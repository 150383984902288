import Muuri from 'muuri';
import type Grid from "muuri";

export default class CategoryFilters {
    constructor() {
        // Initialize Muuri outside the event listener
        this.grid = new Muuri('.news-post-wrapper');

        const filterElements = document.querySelectorAll('.category__item');

        const close = () => {
            filterElements.forEach((item) => {
                    item.classList.remove('active');
            })
        }

        filterElements.forEach((item) => {
            item.addEventListener('click', () => {
                close();
                const selectedItem = item.innerHTML.toLowerCase().replace(/ /g,"-").replace(/-&amp;/g, '');
                
                item.classList.add('active');

                if (selectedItem === "all") {
                    this.grid.filter(".muuri-item");
                } else {
                    this.grid.filter(function (item) {
                        const categories = item.getElement().getAttribute('data-alias');
                        return categories.includes(selectedItem);
                    });
                }
            });
        });
    }
}