export default class ProductDots {
    constructor() {
        const dots = document.querySelectorAll('.single-product-available-colors-dot');
        const select = document.getElementById('pa_colour') as HTMLSelectElement;

        if (!select || !dots) {
            return;
        }

        dots.forEach(dot => {
            dot.addEventListener('click', function() {
                select.value = dot.id;
                const changeEvent = new Event('change', { bubbles: true });
                select.dispatchEvent(changeEvent);
            });
        });
    }
}




