export default class ProductFAQ {
    constructor() {
        const faqTriggers = document.querySelectorAll('.product-faq-icon-box');
        const faqWrappers = document.querySelectorAll('.faq-product-results-wrapper');

        this.hideAllFAQs(faqWrappers);

        faqTriggers.forEach(item => {
            item.addEventListener('click', () => {
                this.hideAllFAQs(faqWrappers);
                this.displayFAQ(item);
            });
        });
    }

    hideAllFAQs(faqWrappers) {
        faqWrappers.forEach(element => {
            element.style.display = 'none';
        });
    }

    displayFAQ(item) {
        const titleText = item.querySelector('.elementor-icon-box-title > span');
        if (titleText) {
            const cleanedText = titleText.textContent.replace(/&/g, '').replace(/\s+/g, '-').toLowerCase();
            const queryText = 'faq' + cleanedText + 'results';
            const queryElement = document.getElementById(queryText);
            if (queryElement) {
                queryElement.style.display = 'block';
            } else {
                console.error('FAQ element not found:', queryText);
            }
        }
    }
}