export default class Menu {
    constructor() {
        const megaSubMenus = document.querySelectorAll('.mega-sub-menu');

        megaSubMenus.forEach(subMenu => {
            subMenu.addEventListener('mouseover', () => {
                const menuItem = subMenu.closest('.mega-menu-item');
                if (menuItem) {
                    const menuLink = menuItem.querySelector('.mega-menu-link');
                    if (menuLink) {
                        menuLink.style.background = '#000';
                        menuLink.style.color = '#fff';
                    }
                }
            });
            
            subMenu.addEventListener('mouseout', () => {
                const menuItem = subMenu.closest('.mega-menu-item');
                if (menuItem) {
                    const menuLink = menuItem.querySelector('.mega-menu-link');
                    if (menuLink) {
                        menuLink.style.background = '';
                        menuLink.style.color = '';
                    }
                }
            });
        });
    }
}